import Logo from 'components/Logo'
import {faGithub} from '@fortawesome/free-brands-svg-icons'
import {Wrapper, LoginButton, LogoWrapper, Title, GitHubIcon} from './style'

type LoginDialogProps = {onClick: () => void}

function LoginDialog({onClick}: LoginDialogProps) {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo withLink={false} imageStyles={{height: 84, width: 84}} />
        <Title>Hydra</Title>
      </LogoWrapper>
      <LoginButton onClick={onClick}>
        {/* @ts-ignore */}
        <GitHubIcon icon={faGithub} />
        <p>Entrar com o GitHub</p>
      </LoginButton>
    </Wrapper>
  )
}

export default LoginDialog
