import styled from '@emotion/styled'

const BoardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  height: 100%;
  margin-right: 24px;
`

export {BoardWrapper}
