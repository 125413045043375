import {useCallback, useEffect, useState} from 'react'
import {useTheme} from '@emotion/react'
import {Priority} from 'components/IssueCard/enums'

export default function useDotColor(priority: Priority) {
  const {
    colors: {danger500, orange, warning500, success500},
  } = useTheme()
  const [dotColor, setDotColor] = useState('')

  const getDotColor = useCallback(
    (priority: Priority) => {
      return {
        Urgente: danger500,
        Alta: orange,
        Média: warning500,
        Baixa: success500,
      }[priority]
    },
    [danger500, orange, warning500, success500],
  )
  useEffect(() => setDotColor(getDotColor(priority)), [getDotColor, priority])

  return dotColor
}
