import {LinkProps} from 'react-router-dom'
import {IconDefinition} from '@fortawesome/free-solid-svg-icons'
import {useTheme} from '@emotion/react'
import {NavLinkWrapper, FontAwesomeIconWrapper} from './style'

type ScreenLinkProps = {
  to: LinkProps['to']
  screenTitle: string
  faIcon: IconDefinition
}

function ScreenLink({to, screenTitle, faIcon}: ScreenLinkProps) {
  const {
    colors: {navLink},
  } = useTheme()

  return (
    <NavLinkWrapper exact to={to} activeStyle={{color: navLink}}>
      {/* @ts-ignore */}
      <FontAwesomeIconWrapper icon={faIcon} />
      {screenTitle}
    </NavLinkWrapper>
  )
}

export default ScreenLink
