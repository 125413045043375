/** @jsxImportSource @emotion/react */
import {useContext} from 'react'
import Header from 'components/Header'
import StatusPanel from './StatusPanel'
import IssuesPerDayChart from './IssuesPerDayChart'
import {
  DashboardWrapper,
  ChartsWrapper,
  ChartWrapper,
  ChartTitle,
} from './style'
import {GlobalContext} from 'contexts/GlobalContext'

function Dashboard() {
  const {slaInfo, hasFirebaseData} = useContext<any>(GlobalContext)

  return (
    <DashboardWrapper>
      <Header
        title="Dashboard"
        subtitle="Histórico e previsão dos SLAs (Service Legal Agreement)"
      />
      <main>
        <StatusPanel
          status={{
            delayedIssues: slaInfo?.outDated,
            barredIssues: slaInfo?.barredCount,
            futureIssues: slaInfo?.future,
            slaPercentage: slaInfo?.sla,
          }}
        />
        {hasFirebaseData ? (
          <ChartsWrapper>
            <ChartWrapper>
              <ChartTitle>QUANTIDADE DE ISSUES POR DIA</ChartTitle>
              <IssuesPerDayChart width="90%" height={500} />
            </ChartWrapper>
            <ChartWrapper>
              <ChartTitle>QUANTIDADE DE ISSUES POR DIA</ChartTitle>
              <IssuesPerDayChart width="90%" height={500} />
            </ChartWrapper>
          </ChartsWrapper>
        ) : (
          <p>Loading charts...</p>
        )}
      </main>
    </DashboardWrapper>
  )
}

export default Dashboard
