import {useContext} from 'react'
import {GlobalContext} from 'contexts/GlobalContext'
import BoardColumn from '../BoardColumn'
import {BoardWrapper} from './style'

function Board() {
  const {newIssues, issuesToBeDone, issuesInProgress} =
    useContext<any>(GlobalContext)

  return (
    <BoardWrapper>
      <BoardColumn title="AGUARDANDO TRIAGEM" issues={newIssues ?? []} />
      <BoardColumn title="A FAZER" issues={issuesToBeDone ?? []} />
      <BoardColumn title="FAZENDO" issues={issuesInProgress ?? []} />
    </BoardWrapper>
  )
}

export default Board
