/** @jsxImportSource @emotion/react */
import {useCallback} from 'react'
import dayjs from 'dayjs'
import Tooltip from 'components/Tooltip'
import {
  CardWrapper,
  StatusRow,
  LinkWrapper,
  Link,
  PriorityDot,
  StatusBadge,
  Title,
  DescriptionRow,
  DateInThePast,
} from './style'
import useDotColor from 'hooks/useDotColor'
import {Priority} from './enums'

type IssueCardProps = {
  issueNumber: number
  issueLink: string
  issueName: string
  SLA: string
  createdBy: string
  assignedTo: string
  reason?: string
  priority: Priority
  status?: string
}

function IssueCard({
  issueNumber,
  issueLink,
  issueName,
  SLA,
  createdBy,
  assignedTo,
  reason,
  priority,
  status,
}: IssueCardProps) {
  const dotColor = useDotColor(priority)

  const getStatusLabel = (statusLabel: string) =>
    ({
      homolog: 'em Homologação',
      barred: 'Barrada',
      pr: 'em Revisão',
      done: 'Homologada',
    }[statusLabel])

  const showStatusLabel =
    status && status !== 'progress' && status !== 'todo' && status !== 'deploy'

  const getFormattedDate = useCallback(() => {
    const [DD, MM, YYYY] = SLA.split('/')
    const dateObject = dayjs(`${MM}-${DD}-${YYYY}`)
    const today = dayjs()

    if (dateObject.isSame(today.subtract(1, 'd'), 'd'))
      return <DateInThePast>Ontem</DateInThePast>

    if (dateObject.isSame(today, 'd')) return 'Hoje'

    if (dateObject.isSame(today.add(1, 'd'), 'd')) return 'Amanhã'

    if (dateObject.isBefore(today, 'd'))
      return <DateInThePast>{dateObject.format('D MMM YYYY')}</DateInThePast>

    return dateObject.format('D MMM YYYY')
  }, [SLA])

  return (
    <CardWrapper>
      <StatusRow>
        <LinkWrapper>
          <Link href={issueLink} target="_blank" rel="noopener">
            #{issueNumber}
          </Link>
          {priority && (
            <Tooltip title={`Prioridade ${priority}`}>
              <PriorityDot dotColor={dotColor} />
            </Tooltip>
          )}
        </LinkWrapper>
        {/* @ts-ignore */}
        {showStatusLabel && <StatusBadge>{getStatusLabel(status)}</StatusBadge>}
      </StatusRow>
      <Title>{issueName}</Title>
      <DescriptionRow>
        SLA: {SLA === '-' ? '-' : getFormattedDate()}
      </DescriptionRow>
      <DescriptionRow>Created by: {createdBy}</DescriptionRow>
      <DescriptionRow>Assigned to: {assignedTo}</DescriptionRow>
      {reason && <DescriptionRow>Motivo: {reason}</DescriptionRow>}
    </CardWrapper>
  )
}

export default IssueCard
