const defaultColors = {
  gray100: '#F1F3F4',
  gray300: '#DEE2E6',
  gray500: '#6C757D',
  gray900: '#202124',

  danger100: '#FFECEE',
  danger500: '#E70202',
  danger900: '#AC0000',

  warning100: '#FEF6D5',
  warning500: '#FCC530',
  warning900: '#C59500',

  success100: '#D3F8E5',
  success500: '#02883C',
  success900: '#005A11',

  black: '#000000',
  white: '#FFFFFF',
  
  orange: '#FF8533',

  link: '#1D6BB7',
  navLink: '#228BE6',
}

export default defaultColors
