import {Redirect} from 'react-router-dom'
import LoginDialog from 'components/LoginDialog'

type UnauthenticatedAppProps = {handleSignIn: () => void}

function UnauthenticatedApp({handleSignIn}: UnauthenticatedAppProps) {
  return (
    <>
      <Redirect to="/signin" />
      <LoginDialog onClick={handleSignIn} />
    </>
  )
}

export default UnauthenticatedApp
