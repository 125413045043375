import {HeaderWrapper, H1Wrapper, H2Wrapper} from './style'

type HeaderProps = {
  title: string,
  subtitle: string
}

function Header({ title, subtitle }: HeaderProps) {
  return (
    <HeaderWrapper>
      <H1Wrapper>{title}</H1Wrapper>
      <H2Wrapper>{subtitle}</H2Wrapper>
    </HeaderWrapper>
  )
}

export default Header
