import styled from '@emotion/styled'

const ColumnWrapper = styled.div`
  margin: 0 8px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.issuesBoardBg};
  height: calc(100% - 24px);
`

const ColumnTitle = styled.h3`
  height: 57.5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0 5%;
  letter-spacing: 0.7px;
  border-bottom: 2px solid ${({theme}) => theme.colors.bodyBg};
`

const CountBadge = styled.span`
  margin-left: 8px; 
  background-color: ${({theme}) => theme.colors.gray500};
  padding: 2px;
  border-radius: 3px;
`;

const IssuesColumnWrapper = styled.div`
  height: calc(100vh - (96px + 57.5px + 24px));
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({theme}) => theme.colors.scrollBgColor};
    border-bottom-right-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.colors.gray500};
    border: 2px solid transparent;
    border-radius: 5px;
    background-clip: content-box;
  }

  div:last-child {
    border-bottom: 0;
  }
`

export {ColumnWrapper, ColumnTitle, CountBadge, IssuesColumnWrapper}
