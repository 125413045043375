import {useEffect, useState} from 'react'
import {getSlaCount} from 'services/hydra'
import {StatusPanelWrapper, StyledH3, StyledH4} from './style'

type StatusPanelProps = {
  status: {
    delayedIssues: number
    barredIssues: number
    futureIssues: number
    slaPercentage: number
  }
}

function StatusPanel({status}: StatusPanelProps) {
  const {delayedIssues, barredIssues, futureIssues, slaPercentage} = status
  const [slaCount, setSlaCount] = useState<Number>()

  useEffect(() => {
    getSlaCount()
      .then(({data: {count}}) => setSlaCount(count ?? 0))
      .catch(error => console.error(error))
  }, [])

  return (
    <StatusPanelWrapper>
      <div>
        <StyledH3>ISSUES ATRASADAS</StyledH3>
        <StyledH4>{delayedIssues ?? '...'}</StyledH4>
      </div>
      <div>
        <StyledH3>ISSUES BARRADAS</StyledH3>
        <StyledH4>{barredIssues ?? '...'}</StyledH4>
      </div>
      <div>
        <StyledH3>ISSUES FUTURAS</StyledH3>
        <StyledH4>{futureIssues ?? '...'}</StyledH4>
      </div>
      <div>
        <StyledH3>
          SLA
          {slaCount
            ? ` (${slaCount} dia${
                slaCount > 1 || slaCount === 0 ? 's' : ''
              } 100%)`
            : ' (...)'}
        </StyledH3>
        <StyledH4>
          {slaPercentage ? Math.round(slaPercentage * 100) + '%' : '...'}
        </StyledH4>
      </div>
    </StatusPanelWrapper>
  )
}

export default StatusPanel
