import styled from '@emotion/styled'

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 8px 8px 0px 8px;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.logoBg};
`

const Image = styled.img`
  width: 56px;
  height: 56px;
`

export {ImageWrapper, Image}
