import styled from '@emotion/styled'

const StatusPanelWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 12px;
  margin-right: 24px;
`

const StyledH3 = styled.h3`
  letter-spacing: 3px;
`

const StyledH4 = styled.h4`
  font-size: 72px; 
  font-weight: 300;
`

export {StatusPanelWrapper, StyledH3, StyledH4}
