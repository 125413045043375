import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

let firebaseApp: firebase.app.App | undefined
try {
  firebaseApp = firebase.app('hydra')
} catch (e) {
  firebaseApp = firebase.initializeApp(firebaseConfig, 'hydra')
}

const auth = firebase.auth(firebaseApp)

const getRef = (path: string) => firebase.database(firebaseApp).ref(path)

export {firebase, auth, getRef}
