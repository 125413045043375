import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'
import 'services/firebase'

dayjs.locale('pt-br')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
