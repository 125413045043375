import {useCallback, useEffect, useState} from 'react'
import {getRef} from 'services/firebase'

// type EventType =
//   | 'value'
//   | 'child_added'
//   | 'child_changed'
//   | 'child_moved'
//   | 'child_removed'

// type useFirebaseProps = {
//   path: string
//   events: []
// }

const useFirebase = ({path, events = []}: any) => {
  // TODO: remove any
  const [firebaseRef, setFirebaseRef] = useState<any>()

  const getFirebaseRef = useCallback(() => {
    const ref = getRef(path)

    if (events)
      // TODO: remove any
      Object.entries(events).forEach(([name, handler]: any) => {
        ref.on(name, handler)
      })

    setFirebaseRef(ref)

    return ref
  }, [events, path])

  useEffect(() => {
    const ref = getFirebaseRef()
    // TODO: remove any
    Object.entries(events).forEach(([name, handler]: any) => {
      ref.on(name, handler)
    })

    setFirebaseRef(ref)

    return () => {
      if (events)
        // TODO: remove any
        Object.keys(events).forEach((name: any) => {
          ref.off(name)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  return {
    ref: firebaseRef,
  }
}

export default useFirebase
