import styled from '@emotion/styled'

const HeaderWrapper = styled.header`
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
`

const H1Wrapper = styled.h1`
  font-size: 24px;
`

const H2Wrapper = styled.h2`
  font-size: 12px;
  color: ${({theme}) => theme.colors.gray500};
`

export {HeaderWrapper, H1Wrapper, H2Wrapper}
