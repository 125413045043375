import styled from '@emotion/styled'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  margin: auto;
  border-radius: 8px;
  background-color: ${({theme}) => theme.colors.issuesBoardBg};

  width: 364px;
  height: 340px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const LoginButton = styled.button`
  margin: 0;
  border-radius: 8px;
  font-size: 20px;

  height: 80px;
  width: 80%;
  background-color: ${({theme}) => theme.colors.issuesBoardBg};
  border: 2px solid ${({theme}) => theme.colors.bodyBg};
  color: ${({theme}) => theme.colors.white};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  :hover {
    // border: 4px solid ${({theme}) => theme.colors.bodyBg};
    background-color: ${({theme}) => theme.colors.bodyBg};
    cursor: pointer;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`

const Title = styled.h1`
  font-size: 66px;
`

const GitHubIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  font-size: 36px;
`

export {Wrapper, LoginButton, LogoWrapper, Title, GitHubIcon}
