import {BrowserRouter as Router} from 'react-router-dom'
import {ThemeProvider, Global} from '@emotion/react'
import axios from 'axios'
import globalStyles from 'styles/global'
import dark from 'styles/themes/dark'
// import blue from 'styles/themes/blue'
import {useCallback, useState, useEffect} from 'react'
import {firebase, auth} from 'services/firebase'
import {User} from 'contexts/GlobalContext'
import AuthenticatedApp from 'AuthenticatedApp'
import UnauthenticatedApp from 'UnauthenticatedApp'

function App() {
  const [userData, setUserData] = useState<User>()
  const [theme] = useState(dark)
  const {
    colors: {bodyBg, white},
  } = theme

  // TODO: Implement button to change page theme
  // const toggleTheme = () => {
  //   setTheme(theme.title === 'dark' ? blue : dark)
  // }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const {uid, displayName} = user
        setUserData({
          id: uid,
          name: displayName,
        })
      }
    })
    return unsubscribe
  }, [])

  const handleSignIn = useCallback(() => {
    const provider = new firebase.auth.GithubAuthProvider()
    auth.signInWithPopup(provider).then(result => {
      if (result.user) {
        const {uid, displayName} = result.user
        setUserData({
          id: uid,
          name: displayName,
        })
        axios.defaults.baseURL = process.env.REACT_APP_HYDRA_API_URL
        axios.defaults.headers.common[
          process.env.REACT_APP_HYDRA_API_TOKEN_HEADER || ''
        ] = process.env.REACT_APP_HYDRA_API_TOKEN
      }
    })
  }, [])

  return (
    <Router>
      <ThemeProvider theme={dark}>
        <Global styles={globalStyles(bodyBg, white)} />
        {userData ? (
          <AuthenticatedApp userData={userData} />
        ) : (
          <UnauthenticatedApp handleSignIn={handleSignIn} />
        )}
      </ThemeProvider>
    </Router>
  )
}

export default App
