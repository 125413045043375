import styled from '@emotion/styled'

const CardWrapper = styled.div`
  width: 90%;
  margin: 16px auto 0 auto;
  border-bottom: 2px solid ${({theme}) => theme.colors.bodyBg};
  padding-bottom: 20px;
`

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Link = styled.a`
  text-decoration: none;
  color: ${({theme}) => theme.colors.link};
`

const PriorityDot = styled.span<{dotColor: string}>`
  margin-left: 8px;
  height: 8px;
  width: 8px;
  background-color: ${({dotColor}) => dotColor};
  border-radius: 50%;
`

const StatusBadge = styled.p`
  background-color: ${({theme}) => theme.colors.gray500};
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
`

const Title = styled.h4`
  margin-top: 4px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DescriptionRow = styled.p`
  margin-top: 4px;
  color: ${({theme}) => theme.colors.gray500};
`

const DateInThePast = styled.span`
  color: ${({theme}) => theme.colors.danger500};
`

export {
  CardWrapper,
  StatusRow,
  LinkWrapper,
  Link,
  PriorityDot,
  StatusBadge,
  Title,
  DescriptionRow,
  DateInThePast,
}
