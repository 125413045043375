import styled from '@emotion/styled'
import {NavLink} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const NavLinkWrapper = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 44px;
  color: ${({theme}) => theme.colors.gray500};
  font-size: 12px;
`

const FontAwesomeIconWrapper = styled(FontAwesomeIcon)`
  font-size: 20px;
`

export {NavLinkWrapper, FontAwesomeIconWrapper}
