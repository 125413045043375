import defaultColors from '../defaultColors'

const dark = {
  title: 'dark',

  colors: {
    bodyBg: '#18191C',
    logoBg: '#1B1B1B',
    issuesBoardBg: '#202124',
    scrollBgColor: '#353535',

    ...defaultColors,
  },
}

export default dark
