import Board from './Board'
import {IssuesWrapper} from './style'
import Header from 'components/Header'

function Issues() {
  return (
    <IssuesWrapper>
      <Header
        title="Issues"
        subtitle="Lista de chamados abertos e finalizados na semana"
      />
      <main>
        <Board />
      </main>
    </IssuesWrapper>
  )
}

export default Issues
