/** @jsxImportSource @emotion/react */
import {Interpolation, Theme} from '@emotion/react'
import hydraLogo from 'assets/logo+white.svg'
import {Link} from 'react-router-dom'
import {ImageWrapper, Image} from './style'

type LogoProps = {
  withLink?: boolean
  imageStyles?: Interpolation<Theme>
}

function Logo({withLink = true, imageStyles}: LogoProps) {
  const ImageComponent = () => (
    <Image src={hydraLogo} alt="Logo" css={imageStyles} />
  )

  return withLink ? (
    <Link to="/dashboard">
      <ImageWrapper>
        <ImageComponent />
      </ImageWrapper>
    </Link>
  ) : (
    <ImageComponent />
  )
}

export default Logo
