/** @jsxImportSource @emotion/react */
import {GlobalContext} from 'contexts/GlobalContext'
import {useContext} from 'react'
import {useTheme} from '@emotion/react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

type IssuesPerDayChartProps = {
  width: number | string
  height: number | string
}

function IssuesPerDayChart({width, height}: IssuesPerDayChartProps) {
  const {countByDate, countByDateTicks} = useContext<any>(GlobalContext)
  const {
    colors: {gray500},
  } = useTheme()

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={countByDate}
        margin={{
          top: 5,
          right: 40,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis
          height={75}
          dataKey="name"
          interval={0}
          angle={-45}
          textAnchor="end"
        />
        <YAxis domain={[0, 'dataMax']} ticks={[...countByDateTicks].sort()} />
        <Tooltip wrapperStyle={{color: gray500}} />
        <Bar dataKey="Issues" fill={gray500} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default IssuesPerDayChart
