import styled from '@emotion/styled'

const DashboardWrapper = styled.div`
  width: 100%;
`

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - (96px + 118px));
  margin-right: 24px;
`

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const ChartTitle = styled.h3`
  margin-bottom: 16px;
  letter-spacing: 3px;
`

export {DashboardWrapper, ChartsWrapper, ChartWrapper, ChartTitle}
