import {useEffect} from 'react'
import Sidebar from 'containers/Sidebar'
import Dashboard from 'containers/Dashboard'
import Issues from 'containers/Issues'
// import Statistics from 'containers/Statistics'
import {Switch, Route, Redirect} from 'react-router-dom'
import {GlobalProvider, User} from 'contexts/GlobalContext'
import useFirebaseData from 'hooks/useFirebaseData'

type AuthenticatedAppProps = {
  userData: User
}

function AuthenticatedApp({userData}: AuthenticatedAppProps) {
  useEffect(() => {
    Notification.requestPermission()
  }, [])

  const [firebaseData] = useFirebaseData()

  return (
    <GlobalProvider value={{userData, ...firebaseData}}>
      <Redirect to="/dashboard" />
      <Sidebar />
      <Screens />
    </GlobalProvider>
  )
}

function Screens() {
  return (
    <Switch>
      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/issues">
        <Issues />
      </Route>
      {/* <Route path="/statistics">
        <Statistics />
      </Route> */}
    </Switch>
  )
}

export default AuthenticatedApp
