import {useContext} from 'react'
import IssueCard from 'components/IssueCard'
import {GlobalContext} from 'contexts/GlobalContext'
import {
  ColumnWrapper,
  ColumnTitle,
  CountBadge,
  IssuesColumnWrapper,
} from './style'

type BoardColumnProps = {
  title: string
  issues: any[]
}

function BoardColumn({title, issues}: BoardColumnProps) {
  const {hasFirebaseData} = useContext(GlobalContext)

  return (
    <ColumnWrapper>
      <ColumnTitle>
        {title} <CountBadge>{issues.length}</CountBadge>
      </ColumnTitle>
      <IssuesColumnWrapper>
        {hasFirebaseData ? (
          issues.map(
            ({
              number,
              title,
              html_url,
              assignee,
              user,
              labels,
              dateString,
            }: any) => {
              const priorityLabel = labels
                .find(({name}: any) => name.includes('Prioridade'))
                ?.name?.split(' ')[1]

              const statusLabel = labels
                .find(({name}: any) => name.includes('status:'))
                ?.name?.split(' ')
                .pop()

              return (
                <IssueCard
                  issueNumber={number}
                  issueLink={html_url}
                  issueName={title.replace(
                    title.match(/SLA\s*[0-9]{2}\/[0-9]{2}\/[0-9]{4}/),
                    '',
                  )}
                  SLA={dateString ?? '-'}
                  createdBy={user.login}
                  assignedTo={assignee?.login ?? '-'}
                  priority={priorityLabel}
                  status={statusLabel}
                  key={number}
                />
              )
            },
          )
        ) : (
          <p>Loading...</p>
        )}
      </IssuesColumnWrapper>
    </ColumnWrapper>
  )
}

export default BoardColumn
