import {css} from '@emotion/react'

const globalStyles = (backgroundColor: string, color: string) => css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    height: 100%;
    width: 100%;
  }

  body {
    background: ${backgroundColor};
    font-size: 14px;
    color: ${color};
    font-family: 'museo-sans', sans-serif;
    height: 100%;
    width: 100%;
  }

  main {
    height: calc(100vh - 96px);
    width: calc(100vw - 96px);
  }

  #root {
    display: flex;
    height: 100%;
    width: 100%;
  }
`

export default globalStyles
