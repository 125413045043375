import {createContext} from 'react'

type User = {
  id: string
  name: string | null
}

const GlobalContext = createContext({} as any)

const GlobalProvider = GlobalContext.Provider

const GlobalConsumer = GlobalContext.Consumer

export {GlobalContext, GlobalProvider, GlobalConsumer}

export type {User}
