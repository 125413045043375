/** @jsxImportSource @emotion/react */
import Logo from 'components/Logo'
import ScreenLink from 'components/ScreenLink'
import {
  faChartBar,
  faCheck,
  // faChartLine,
} from '@fortawesome/free-solid-svg-icons'

function Sidebar() {
  return (
    <nav>
      <ul css={{listStyle: 'none'}}>
        <li>
          <Logo />
        </li>
        <li css={{marginTop: 20}}>
          <ScreenLink
            to="/dashboard"
            screenTitle="Dashboard"
            faIcon={faChartBar}
          />
        </li>
        <li css={{marginTop: 24}}>
          <ScreenLink to="/issues" screenTitle="Issues" faIcon={faCheck} />
        </li>
        {/* <li css={{marginTop: 24}}>
          <ScreenLink
            to="/statistics"
            screenTitle="Estatísticas"
            faIcon={faChartLine}
          />
        </li> */}
      </ul>
    </nav>
  )
}

export default Sidebar
