import {useState} from 'react'
import dayjs from 'dayjs'
import useFirebase from 'hooks/useFirebase'

export default function useFirebaseData() {
  const [firebaseData, setFirebaseData] = useState<any>({})

  const handleValueChange = (snapshot: any) => {
    const receivedData = snapshot.val() || {}
    if (receivedData !== firebaseData) {
      const {issues = [], newIssues = []} = receivedData

      const barredCount = [...issues, ...newIssues].reduce(
        (count: any, {labels}: any) => {
          if (labels.some(({name}: any) => name.includes('barred'))) count++
          return count
        },
        0,
      )
      const issuesToBeDone = []
      const issuesInProgress = []

      for (let issue of issues) {
        const {labels} = issue
        if (
          labels.some(
            ({name}: any) =>
              name.includes('in progress') ||
              name.includes('homolog') ||
              name.includes('barred') ||
              name.includes('has pr'),
          )
        )
          issuesInProgress.push(issue)
        else issuesToBeDone.push(issue)
      }

      const countByDate = Object.entries(
        JSON.parse(receivedData.countByDate),
      ).map(([date, Issues]) => {
        const [DD, MM, YYYY] = date.split('/')
        const dateObject = dayjs(`${MM}-${DD}-${YYYY}`)
        return {
          name: dateObject.format('D MMM'),
          Issues,
        }
      })

      const countByDateTicks = new Set(countByDate.map(({Issues}) => Issues))

      if (
        firebaseData.newIssues?.length > 0 &&
        receivedData.newIssues > firebaseData.newIssues
      ) {
        const [newIssue] = receivedData.newIssues
        const {title, html_url} = newIssue
        const notification = new Notification('Nova issue aberta', {
          body: title,
        })
        notification.onclick = () => window.open(html_url, '_blank')
      }

      setFirebaseData({
        ...receivedData,
        countByDateTicks,
        countByDate,
        issuesToBeDone,
        issuesInProgress,
        slaInfo: {...receivedData.slaInfo, barredCount},
        hasFirebaseData: true,
      })

      console.log({
        ...receivedData,
        countByDateTicks,
        countByDate,
        issuesToBeDone,
        issuesInProgress,
        slaInfo: {...receivedData.slaInfo, barredCount},
        hasFirebaseData: true,
      })
    }
  }

  useFirebase({
    path: 'dashboard',
    events: {
      value: handleValueChange,
    },
  })

  return [firebaseData, setFirebaseData]
}
