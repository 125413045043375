import Tippy, {TippyProps} from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'

type TooltipProps = {
  title: TippyProps['content']
  placement?: TippyProps['placement']
  isVisible?: boolean
  children: TippyProps['children']
}

const Tooltip = ({
  title,
  placement = 'top',
  isVisible = true,
  children,
}: TooltipProps) =>
  isVisible ? (
    <Tippy content={title} placement={placement}>
      {children}
    </Tippy>
  ) : (
    children
  )

export default Tooltip
